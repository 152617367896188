import FlushingDetailTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-redness/flushing-detail/FlushingDetailTemplate';

import { FLUSH_DURATION, FLUSH_LOCATION, GENDER } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/Redness/FlushingDetail/FlushingDetailTemplate'
};

const createStory = props => () => ({
  components: { FlushingDetailTemplate },
  storyProps: props,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template: `<div :style='$options.wrapStyles'>
    <flushing-detail-template v-bind="$options.storyProps" />
  </div>`
});

export const Base = createStory({
  hotFlushes: 'yes',
  flushesDuration: FLUSH_DURATION.FEW_YEARS,
  flushLocations: [FLUSH_LOCATION.NECK],
  gender: GENDER.FEMALE
});

export const Menopause = createStory({
  showMenopauseQuestion: true,
  hotFlushes: 'yes',
  flushesDuration: FLUSH_DURATION.FEW_YEARS,
  flushLocations: [FLUSH_LOCATION.NECK],
  gender: GENDER.FEMALE
});

export const WithOtherOption = createStory({
  hotFlushes: 'yes',
  flushesDuration: FLUSH_DURATION.FEW_YEARS,
  flushLocations: [FLUSH_LOCATION.NECK, FLUSH_LOCATION.UPPER_BODY, 'other'],
  otherFlushLocations: 'a, b, c',
  gender: GENDER.FEMALE
});
